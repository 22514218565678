@font-face {
  font-family: 'Monaspace Neon';
  src: url('./fonts/MonaspaceNeon-Regular.woff') format('woff');
}

html {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 87.5%;
  font-weight: 400;
  scrollbar-color: var(--highlight) var(--highlight-weak);
  scrollbar-width: thin;
  --bg-color: #ffffff;
  --bg-color-secondary: #f0f0f0;
  --text-color: #000000;
  --text-color-secondary: #4a4a4a;
  --text-color-tertiary: #999999;
  --line-color: #cccccc;
  --line-color-light: #dddddd;
  --line-color-strong: #bbbbbb;
  --line-color-stronger: #aaaaaa;
  --highlight: #00000020;
  --highlight-weak: #00000010;
  --highlight-strong: #00000030;
  --accent-color-coral: #ff7f50;
  --accent-color-red: #ff4500;
  --url-color: #007bff;
}

html.dark {
  --bg-color: #1c2028;
  --bg-color-secondary: #232830;
  --text-color: #d7d8db;
  --text-color-secondary: #b4b6bd;
  --text-color-tertiary: #777d8b;
  --line-color: #707070;
  --line-color-light: #818181;
  --line-color-strong: #4e4e4e;
  --line-color-stronger: #3e3e3e;
  --highlight: #ffffff20;
  --highlight-weak: #ffffff10;
  --highlight-strong: #ffffff30;
  --accent-color-coral: #D77073;
  --accent-color-red: #d25446;
  --url-color: #4da3ff;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  margin: 0;
}

footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  min-height: 50px;
  height: 100%;
  background-color: var(--bg-color-secondary);
  line-height: 1.5;
  border-top: 0.5px solid var(--line-color-strong);
  padding: 40px 20px;
  color: var(--text-color-tertiary);
}

.footer-container {
  max-width: 700px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.footer-links {
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.github-icon {
  margin-right: 4px;
  width: 1.1em;
  height: 1.1em;
}

a {
  color: var(--url-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

button {
  font-weight: 500;
}

hr {
  border: none;
  border-top: 0.5px solid var(--line-color);
  margin: 16px 0px;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  font-weight: 200;
}